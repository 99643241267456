<template>
    <Breadcrumb title="Me sukses!" subtitle="" />
    <ThankYou/>
    <Footer/>
</template>

<script>
import Breadcrumb from '../../components/common/Breadcrumb.vue';
import ThankYou from '../../components/ThankYou/ThankYou.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
    name:'ServicesPage',
    components:{
        Breadcrumb,
        ThankYou,
        Footer,
    }
}
</script>