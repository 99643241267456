import axios from "axios";
// Get the hostname from the current URL
const hostname = window.location.hostname;
const instance = axios.create({
  baseURL: `https://${hostname}/arrk/`,
  headers: {
    "Content-Type": "application/json",
  },
});
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
export default instance;