<template>
   <footer class="footer" data-bg-color="#222429"
      :style="{backgroundImage:'url('+ require('../../assets/img/bg/04.png') + ')'}">
      <div class="footer-top pt-115 pb-90">
         <div class="container">
            <div class="row">
               <div class="col-xl-3 col-lg-4 col-md-6">
                  <div class="footer-widget mb-30">
                     <div class="footer-logo mb-30">
                        <router-link to="/">
                           <img src="../../assets/img/logo/logo.png" alt="">
                        </router-link>
                     </div>
                     <div class="footer-text mb-20">
                        <p>Kurdo që ju keni nevojë, ne jemi aty për ju!</p>
                     </div>
                  </div>
               </div>
               <div class="col-xl-3 col-lg-3 col-md-6 d-lg-none d-xl-block">
                  <ul>
                     <li class="d-flex mb-25">
                        <div class="rc__comments-icon mr-30">
                           <i class="fas fa-phone"></i>
                        </div>
                        <div class="footer-info">
                           <h6>Telefononi në çdo kohë</h6>
                           <p><span><a href="tel:(+383)46-100-011" style="color:white ;">046 100 011</a></span></p>
                        </div>
                     </li>
                     <li class="d-flex mb-25">
                        <div class="rc__comments-icon mr-30">
                           <i class="fas fa-envelope"></i>
                        </div>
                        <div class="footer-info">
                           <h6>Dergoni email në çdo kohë</h6>
                           <p> <span><a href="mailto:asistencarrugore.info@gmail.com"
                                    style="color:white ; ">asistencarrugore.info@gmail.com</a></span></p>
                        </div>
                     </li>
                  </ul>
               </div>
               <div class="col-xl-3 col-lg-4 col-md-6 d-lg-block">
                      <h3 class="footer-title text-end">Na ndiqni në rrejetet sociale</h3>
                     <div class="footer-social text-start text-md-center">
                     <a href="https://www.facebook.com/asistencarrugoreekosoves" target="_blank"><i class="fab fa-facebook-f"></i></a>
                     <!-- <a href="#"><i class="fab fa-youtube"></i></a>
                     <a href="#"><i class="fab fa-linkedin"></i></a>
                     <a href="#"><i class="fab fa-twitter"></i></a> -->
                     <a href="https://www.instagram.com/asistencarrugore.ks/" target="_blank"><i class="fab fa-instagram"></i></a>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="copyright-area pt-25 pb-20">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="footer-copyright">
                     <p class="white-color">Copyright © Asistenca Rrugore e Kosoves. All Rights Reserved</p>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <vue-easy-lightbox :visible="visible" :imgs="galleryData.map(img => img.img)" :index="index" @hide="handleHide">
      </vue-easy-lightbox>
   </footer>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
   name:'HomeFooter',
    components: {
     VueEasyLightbox,
    },
   data () {
      return {
         visible: false,
         index: 0,
         galleryData:[
            {
               id:'1',
               img:require(`@/assets/img/instagram/ins1.jpg`)
            },
            {
               id:'2',
               img:require(`@/assets/img/instagram/ins2.jpg`)
            },
            {
               id:'3',
               img:require(`@/assets/img/instagram/ins3.jpg`)
            },
            {
               id:'4',
               img:require(`@/assets/img/instagram/ins4.jpg`)
            },
            {
               id:'5',
               img:require(`@/assets/img/instagram/ins5.jpg`)
            },
            {
               id:'6',
               img:require(`@/assets/img/instagram/ins6.jpg`)
            },
         ]
      }
   },
   methods: {
      showImg(index) {
        this.index = index
        this.visible = true
      },
      handleHide() {
        this.visible = false
      }
    }
}
</script>
<style scoped>
.footer-info h6,p{
   color: white;
}
</style>