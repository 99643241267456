<template>
    <section id="contact-area" class="contact-area pt-120 pb-80 fix">
        <div class="container">
            <div class="row">
                <div class="col-xxl-5 col-xl-6 col-lg-6">
                    <div class="section-title-wrapper mb-15">
                        <h5 class="section-subtitle mb-20">Ju keni zgjedhur</h5>
                        <h2 class="section-title">{{title}}</h2>
                        <h2 class="section-title">{{price}} €</h2>
                    </div>
                    <div class="contact-info mr-50 mr-xs-0  mr-md-0">
                        <h5 class="section-subtitle mb-20">Për të vazhduar me anëtarësimin ju duhet të pajtoheni me termat dhe kushtet</h5>
                        <div class="col-xxl-12 col-xl-12 mb-20">
                            <b-button variant="link" v-b-modal.modal-1>Lexo termat dhe kushtet</b-button>

                            <b-modal id="modal-1" title="Termat dhe Kushtet">
                                <img :src="imageUrl" style="height: 100%; width: 100%;" />
                            </b-modal>

                        </div>

                        <div class="col-xxl-12 col-xl-12 mb-20">
                            <b-form-checkbox id="checkbox-1" v-model="termatKushtet" name="checkbox-1" :value="true"
                                :unchecked-value="false">
                                Pranoj termat dhe kushtet
                            </b-form-checkbox>

                        </div>

                    </div>
                </div>
                <div v-if="termatKushtet" class="col-xxl-7 col-xl-6 col-lg-6">
                    <IndividualForm :title="title" :price="price" :packageId="packageId"></IndividualForm>
                    <!-- <BiznesForm v-if="individual === false" :title="title" :price="price" :packageId="packageId"></BiznesForm> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import IndividualForm from "./IndividualForm.vue";
// import BiznesForm from "./BiznesForm.vue";
export default {
    name:'ContactArea',
    props:['title','price','packageID'],
    components:{
    IndividualForm,
   // BiznesForm
},
    data(){
        return{
            termatKushtet: true,
            packageId:'',
            imageUrl: "" // Initialize imageUrl as an empty string
        }
    },
    mounted(){
      // Construct the URL and set it as the imageUrl
       const domain = window.location.origin;
       this.imageUrl = domain + '/ArrkDocuments/Terms.jpg';
        //this.imageUrl = 'https://www.asistencarrugore.com' + '/ArrkDocuments/Terms.jpg';
        var el = document.getElementById('contact-area');
        el.scrollIntoView();
        this.packageId = this.$route.params.id;
    }
}
</script>