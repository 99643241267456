<template>
     <section class="page__title p-relative d-flex align-items-center grey-bg-2" data-overlay="dark" data-opacity="7">
            <div class="page__title-bg" :style="{backgroundImage:'url('+ require('../../assets/img/page-title/page-title-1.jpg') + ')'}"></div>
            <div class="container">
                  <div class="row">
                     <div class="col-xl-12">
                        <div class="page__title-content mt-100 text-center">
                              <h2>{{title}}</h2>
                              <nav aria-label="breadcrumb">
                                 <ol class="breadcrumb justify-content-center">
                                    <li class="breadcrumb-item"><router-link to="/">Faqja kryesore</router-link></li>
                                    <!-- <li class="breadcrumb-item active" aria-current="page">{{subtitle}}</li> -->
                                 </ol>
                              </nav>
                        </div>
                     </div>
                  </div>
            </div>
         </section>
</template>

<script>
export default {
    name:'BreadcrumbComponent',
    props:{
        title:String,
        subtitle:String,
    }
}
</script>