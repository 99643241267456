<template>
  <div class="slider-area fix">
    <div class="slider-active swiper-container">
      <swiper
        ref="mySwiper"
        :modules="modules"
        :effect="'fade'"
        :loop="true"
        :autoplay="{delay: 5000}"
        :navigation="{nextEl:'.swiper-button-next',prevEl:'.swiper-button-prev'}"
      >
        <swiper-slide v-for="item in sliders" :key="item.id">
          <div class="slider-list swiper-slide d-flex align-items-center pt-70">
            <div class="slider-shape"></div>
            <div class="slider-shape-line"></div>
            <div class="slider-shape-line2"></div>
            <div class="slider-bg img-zoom"
            :style="{ backgroundImage:'url(' + require('../../assets/img/slider/' + item.image) + ')', }"></div>
            <div class="container">
              <div class="row">
                <div class="col-12 position-relative">
                  <div class="slider-circle-shape"></div>
                  <div class="slider-text z-index text-center">
                    <span class="slider-sub-title">{{item.subject}}</span>
                    <h2 class="slider-title pre-formatted">
                      {{ item.title }}
                    </h2>
                    <div class="slide-btn mt-30">
                      <a id="antButton" @click="goto('pricing')" class="tp-btn">{{item.btnText}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      

      <!-- If we need navigation buttons -->
      <div class="swiper-button-prev sp-arrow">
        <i class="far fa-angle-left"></i>
      </div>
      <div class="swiper-button-next sp-arrow">
        <i class="far fa-angle-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, EffectFade,Autoplay  } from "swiper";
export default {
  name: "HeroSlider",
  props:["scrollMethod"],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      sliders: [
        {
          id: "1",
          image: "slider5.jpg",
          subject: 'Miku juaj në çdo udhëtim',
          title: "ASISTENCA \nRRUGORE \nE KOSOVËS",
          btnText:'Antarsohu',
        },
        {
          id: "2",
          image: "slider6.jpg",
          subject: 'Jemi gjithmonë këtu',
          title: 'LINJA \n EMERGJENTE\n24/7',
          btnText:'Antarsohu',
        },
        // {
        //   id: "3",
        //   image: "sliderLuli.jpg",
        //   subject: ' ',
        //   title: ' ',
        //   btnText:'Antarsohu',
        // },
      ],
    };
  },

  setup() {
    return {
      modules: [Navigation, EffectFade,Autoplay ],
    };
  },
  methods:{
    goto(id){
      this.scrollMethod(id);
    }
  }
};
</script>
<style scoped>
#antButton:hover {
  cursor: pointer;
}
</style>
