<template>
    <div class="col-xxl-12 col-xl-12 mb-20">
        <div v-if="saveError" @click="saveError = !saveError" class="alert alert-danger" role="alert"
            v-html="errorMessage">
        </div>
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
            <label @click="individual = true" class="btn btn-outline-secondary" for="btnradio1">Person
                fizik</label>

            <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
            <label @click="individual = false" class="btn btn-outline-secondary" for="btnradio2">Biznes</label>

        </div>
    </div>
    <div class="contact-form">
        <form id="contact-form">
            <div class="row">

                <h3>Të dhënat personale</h3>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <input name="name" type="text" :placeholder="Emri" v-model="member.firstName">
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <input name="surname" type="text" :placeholder="Mbiemri" v-model="member.lastName">
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <select name="Cities" id="cities" v-model="member.city">
                        <option v-for="city of cities" :key="city.id" :value="city.id">{{ city.city }}</option>
                    </select>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <input name="address" type="text" placeholder="Adresa" v-model="member.address">
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <input id="phone" name="phone" type="text" placeholder="Telefoni" v-model="member.phone"
                        v-maska="'04#-###-###'">
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <input id="email" name="email" type="email" placeholder="Email" v-model="member.email">
                </div>
                <h3>Të dhënat e vetures</h3>
                <div v-if="vehicleError" class="alert alert-danger" role="alert" v-html="vehicleMessage">
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <select name="Cities" id="cities" v-model="vehicle.brand">
                        <option v-for="car of cars" :key="car.name" :value="car.id">{{ car.name }}</option>
                    </select>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <input name="model" type="text" placeholder="Modeli i vetures" v-model="vehicle.model">
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <input name="model" type="number" placeholder="Viti i prodhimit" v-model="vehicle.year">
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <input name="plates" type="text" placeholder="Targat" v-model="vehicle.licensePlate"
                        v-maska="'##-###-SS'">
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                    <input name="registration" type="text" placeholder="Numri i shahësise"
                        v-model="vehicle.chassisNumber" @input="formatLicensePlate">
                </div>
                <div class="col-xxl-12 col-xl-12 mb-20">
                    <br>
                    <br>
                    <button :disabled="isLoading" @click.prevent="registerMember" class="tp-btn">Anëtaresohu</button>
                </div>
            </div>
        </form>
    </div>
    <invoice-print v-show="false" :member="member" :vehicles="vehicles" ref="InvoiceFrom" :title="title" />
</template>
<script>
import cities from '@/json/cities.json'
import cars from '@/json/car_brands.json'
import InvoicePrint from '../Invoice/InvoicePrint.vue'
import Swal from 'sweetalert2'

export default {
    props: ['packageId', 'price', 'title'],
    components: {
        InvoicePrint
    },
    data() {
        return {
            isLoading: false,
            vehicles: [],
            cars: cars,
            cities: cities,
            member: {
                id: null,
                firstName: '',
                lastName: '',
                city: 0,
                address: '',
                phone: '04',
                clientType: 1,
                grossAmount: 0,
                tax: 0,
                startDate: '',
                endDate: null,
                status: '',
                agent: 1,
                subagent: 1,
                clientID: '',
                email: '',
                Vehicle: [],
            },
            vehicle: {
                id: null,
                brand: 0,
                model: '',
                licensePlate: '',
                chassisNumber: '',
                package: 0,
                withTaxes: true,
                year: null
            },
            show: false,
            vehicleError: false,
            vehicleMessage: '',
            errorMessage: '',
            saveError: false,
            Emri: 'Emri',
            Mbiemri: 'Mbiemri',
            individual: true

        }
    },
    methods: {

        generateDoc() {
            this.$refs.InvoiceFrom.exportToPDF()
        },
        registerMember() {
            if (this.isLoading) {
                return; // Exit early if the request is already in progress
            }
            this.saveError = false;
            this.errorMessage = '';

            // Check first name length
            if (this.member.firstName.length < 3) {
                this.saveError = true;
                this.isLoading = false;
                this.errorMessage = '- Emri minimum 3 karaktere'
            }

            // Check last name length
            if (this.member.lastName.length < 3) {
                this.saveError = true;
                this.isLoading = false;
                this.errorMessage += '<br/>- Mbiemri minimum 3 karaktere'
            }

            // Check city selection
            if (this.member.city === 0) {
                this.saveError = true;
                this.isLoading = false;
                this.errorMessage += '<br/>- Ju lutem zhgjedhni qytetin'
            }

            // Phone number validation (check if it's empty)
            if (this.member.phone === '') {
                this.saveError = true;
                this.isLoading = false;
                this.errorMessage += '<br/>- Ju lutem plotesoni numrin e telefonit'
            } else {
                // Add phone number format validation: must start with '04' and match '04#-###-###'
                const phonePattern = /^04\d-\d{3}-\d{3}$/;
                if (!phonePattern.test(this.member.phone)) {
                    this.saveError = true;
                    this.isLoading = false;
                    this.errorMessage += '<br/>- Numri i telefonit duhet të fillojë me 04 dhe të jetë në formatin 04#-###-###';
                }
            }

            // Email validation
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (this.member.email === '' || !emailPattern.test(this.member.email)) {
                this.saveError = true;
                this.isLoading = false;
                this.errorMessage += '<br/>- Ju lutem plotesoni një email valid'
            }

            // If there are no validation errors
            if (!this.saveError) {
                this.isLoading = true; // Start the loading state
                const error = this.validateVehicle(this.vehicle);
                if (error) {
                    this.vehicleError = true;
                    this.isLoading = false;
                } else {
                    this.vehicles = [];
                    this.vehicle.package = this.Package;
                    this.vehicles.push(this.vehicle);

                    this.member.Vehicle = this.vehicles;
                    this.member.grossAmount = this.vehicles.length * (this.price / 1.18);
                    this.member.tax = this.vehicles.length * this.price - this.member.grossAmount;
                    this.member.status = 1;
                    this.member.agent = 0;
                    this.member.subagent = 0;
                    this.member.startDate = new Date();

                    this.$store.dispatch('members/createMember', this.member).then((response) => {
                        this.member = response.member;
                        if (response.code === 200) {
                            this.generateDoc();
                            Swal.fire({
                                icon: 'success',
                                title: 'Kërkesa për anëtarësim u dërgua me sukses!',
                                showConfirmButton: false,
                                timer: 3000
                            });
                            this.$router.push({ path: '/thanks', query: { success: true } });
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: response.message,
                                showConfirmButton: false,
                                timer: 3000
                            });
                        }
                    })
                        .catch((error) => {
                            // Error handling
                            this.$swal('Error', error.message, 'error');
                        })
                        .finally(() => {
                            this.saveError = false;
                            this.isLoading = false; // Reset the isLoading flag in both success and failure cases
                        });
                }
            }
        },
        resetMember() {
            this.member = {
                id: null,
                firstName: '',
                lastName: '',
                city: 1,
                address: '',
                phone: '',
                clientType: 1,
                grossAmount: 0,
                tax: 0,
                startDate: '',
                endDate: null,
                status: '',
                agent: 1,
                subagent: 1,
                clientID: '',
                email: '',
                Vehicle: [],
            }
        },
        removeVehicle(index) {
            this.vehicles.splice(index, 1);
        },
        validateVehicle(vehicle) {
            let error = false;
            if (vehicle.brand === 0) {
                this.vehicleMessage = '- Zgjedhni Markën e vetures!'
                error = true;
            }
            if (vehicle.model.length < 3) {
                this.vehicleMessage = this.vehicleMessage + '<br/>- Specifikoni modelin!'
                error = true;
            }
            var currentYear = new Date().getFullYear();
            if (vehicle.year < 1990 || vehicle.year > currentYear) {
                this.vehicleMessage = this.vehicleMessage + '<br/>- Vetura me këtë vit të prodhimit nuk mund të antarësohet!'
                error = true;
            }
            return error;
        },
        resetVehicle() {
            this.vehicle = {
                id: null,
                brand: 0,
                model: '',
                licensePlate: '',
                chassisNumber: '',
                package: 3,
                withTaxes: true,
                year: null
            }
        },
        getVehicleImgByName(name) {
            for (const car of cars) {
                if (car.name === name) {
                    return car.logo;
                }
            }
        },
        formatLicensePlate(event) {
            let inputValue = event.target.value;

            // Remove any characters that are not numbers or letters
            inputValue = inputValue.replace(/[^0-9a-zA-Z]/g, "");

            // Convert to uppercase
            inputValue = inputValue.toUpperCase();
            // Update the v-model with the formatted value
            this.vehicle.chassisNumber = inputValue;

            // If you want to limit the input length, you can add this:
            // if (inputValue.length > 7) {
            //   this.vehicle.licensePlate = inputValue.slice(0, 7);
            // } else {
            //   this.vehicle.licensePlate = formattedValue;
            // }
        }
    },
    computed: {
        Package() {
            return this.packageId;
        }
    },
    watch: {
        individual(oldValue, newValue) {
            console.log('newValue', newValue)
            if (!oldValue) {
                this.Emri = 'Emri biznesit'
                this.Mbiemri = 'Numri i bisnesit'
                this.member.clientType = 2
            } else {
                this.Emri = 'Emri'
                this.Mbiemri = 'Mbiemri'
                this.member.clientType = 1
            }
        },
        // eslint-disable-next-line
        'vehicle.licensePlate': function (newVal, oldVal) {
            // Convert the license plate value to uppercase
            this.vehicle.licensePlate = newVal.toUpperCase();
        }

    }
}
</script>