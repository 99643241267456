<template>
    <div class="about-area pt-120 pb-90">
            <div class="container">
               <div class="row">
                  <div class="col-xl-6 col-lg-6">
                     <div class="ab-box pl-50 mb-30">
                        <div class="sec-wrapper">
                           <h2 class="section-title">Çfarë ofrojmë <span>ne</span></h2>
                           <p style="font-size: 18px;"><span>ASISTENCA RRUGORE E KOSOVES  </span>ofron shërbime të shkëlqyera. Ajo që na dallon nga të tjerët është aftësia jonë për të përshtatur ofertën tonë për nevojat e klientëve, si dhe ekipin tonë fantastik të specialistëve.
Pavarësisht se sa unike apo sfiduese është kërkesa juaj, ju mund të mbështeteni tek ne për të gjetur zgjidhjen e duhur për ju. Shfletoni produktet dhe shërbimet tona më poshtë dhe na kontaktoni.</p>
                        </div>
                        <div class="ab-btn">
                           <a @click="goto('pricing')" class="tp-btn">Antarsohu</a>
                        </div>
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-6">
                     <div class="ab-wrapper pos-rel">
                        <div class="ab-since">
                           <h4>Miku juaj
                           <br>ne çdo udhëtim</h4>
                        </div>
                        <div class="row gx-2 mb-30">
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/about/abs-sm3.jpg" alt="">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/about/abs-sm1.jpg" alt="">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/about/abs-sm2.jpg" alt="">
                              </div>
                           </div>
                           <div class="col-6">
                              <div class="ab-img img-filter">
                                 <img src="../../assets/img/about/abs-sm4.jpg" alt="">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
</template>

<script>
export default {
    name:'AboutUs',
    props:["scrollMethod"],
    methods:{
      goto(id){
         this.scrollMethod(id);
      }
    }
}
</script>