<template>
    <div class="main-services grey-bg pt-120 pb-90" :style="{backgroundImage:'url(' + require('../../assets/img/pattern/pt1.png') +')'}">
           <div class="container">
              <div class="row mb-60">
                 <div class="col-12">
                    <div class="sec-wrapper text-center">
                       <h2 class="section-title">FALEMINDERIT</h2>
                    </div>
                 </div>
              </div>
              <div class="row text-center">
                 <div v-for="service in servicesData" :key="service.id" class="col-xl-12 col-lg-12 col-md-12 mb-30">
                    <div class="mfbox mfbox-white">
                       <div class="mf-shape"></div>
                       <div class="mfbox__icon mb-15">
                          <i :class="service.icon"></i>
                       </div>
                       <div class="mfbox__text">
                          <h3 class="mf-title" v-html="service.title"></h3>
                          <div>
                          <p >Antarësimi juaj është procesuar.</p>   
                          <p>Ne do të përgatisim Kartelen dhe do ta sjellim në adresen tuaj brenda 48 ore.</p>
                          <p>Fatura është gjeneruar te downloads në paisjen tuaj. </p> 
                          <p>Gjithashtu në e-mail tuaj tashmë keni pranuar faturen. </p>   
                          <p>Pagesen mund ta bëni me transfer bankar apo cash kur ne të ju sjellim kartelen.</p>   
                          <p>Ne punojmë vazhdimisht për të përmirësuar përvojën tuaj me ne për të siguruar që të përmbushim dhe tejkalojmë pritjet tuaja.</p>
                          <p>Ju lutemi të na kontaktoni në çdo kohë për çdo pyetje ose kërkesë që keni në 046100011. </p>
                          <p>Jemi këtu për ju!</p></div>




                       </div>
                       <div class="mf-btn">
                          <router-link class="squire-btn" to="/">
                             <i class="fal fa-angle-right"></i>
                          </router-link>
                       </div>
                    </div>
                 </div>
                 
              </div>
           </div>
        </div>
</template>

<script>

export default {
   name:'ThankYou',
   data () {
     return {
        servicesData:[
           {
              id:'1',
              icon:'flaticon-award',
              title:'Ju keni dërguar me sukses të dhënat për anëtarësim tek Asistenca'
           },
        ]
     }
  },
  beforeCreate(){
    if(this.$route.query.success != 'true'){
      this.$router.push('/')
    }
  }
}
</script>
<style scoped>
.p-text-left {
    text-align: left !important;
}
</style>