<template>
    <Header :scrollMethod="goto"/>
    <HeroSlider id="home" :scrollMethod="goto"/>
    <About id="about" :scrollMethod="goto"/>
	<PricingArea id="pricing"/>
    <Services id="services"/>
    <Counter id="counter"/>
    <Faq id="faq"/>
    <Footer id="footer"/>
    <a href="#" id="toTop" style="display: inline;"><span id="toTopHover" style="opacity: 0;"></span>
    </a>
</template>

<script>
import Header from '../../components/Home/Header.vue';
import HeroSlider from '../../components/Home/HeroSlider.vue';
import About from '../../components/Home/About.vue';
import Counter from '../../components/Home/Counter.vue';
import Faq from '../../components/Faq/FaqArea.vue'
import Footer from '../../components/Home/Footer.vue';
import PricingArea from '../../components/Pricing/PricingArea.vue'
import Services from '../../components/Home/Services.vue'

export default {
    name:'HomePage',
    components:{
        Header,
        HeroSlider,
        Services,
        About,
        Counter,
        Faq,
        Footer,
        PricingArea
		},
	methods: {
		goto(id) {
			var element = document.getElementById(id);
			element.scrollIntoView();
		},
	}
}
</script>

<style scoped>

#toTop {
	background-color: #ffcf00;
	border-radius: 30%;
	bottom: 10px;
	color: transparent;
	display: none;
	font-size: 0;
	height: 60px;
	opacity: 0.7;
	overflow: hidden;
	position: fixed;
	right: 10px;
	text-decoration: none;
	width: 60px;
	z-index: 1000;
}
#toTop:after {
	bottom: 0;
	color: #fff;
	content: "\f106";
	display: block;
	font-family: "Font Awesome 5 Pro";
	font-size: 16px;
	left: 0;
	line-height: 60px;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

#toTopHover,
#toTop:hover {
	bottom: 3px;
	opacity: 1;
}

#toTop:hover:after {
	color: #fff;
}

#toTop:active,
#toTop:focus {
	outline: medium none;
}
</style>
