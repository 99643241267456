import MemberService from '../services/members.service'
/* eslint-disable */
export const members = {
    namespaced: true,
    actions:{
        createMember({},params){
            return MemberService.createMember(params).then(
                response => {
                  return Promise.resolve(response.data)
                },
                error => {
                  return Promise.reject(error);
                }
              );
        },

    }
   
}