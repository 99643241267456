<template>
   <header>
      <div id="header-sticky"
         :class="`${isSticky ? 'header-area header-transparent header-pad header-border sticky' : 'header-area header-transparent header-pad header-border'}`">
         <div class="container-fluid">
            <div class="row align-items-center">
               <div class="col-xl-1 col-lg-2 col-6">
                  <div class="logo logo-border">
                     <router-link to="/">
                        <img class="logo-white" src="../../assets/img/logo/logo.png" alt="logo">
                     </router-link>
                  </div>
               </div>
               <div class="col-xl-8 col-lg-8 d-none d-lg-block">
                  <div class="main-menu text-center">
                     <nav id="mobile-menu">
                        <ul>
                           <li>
                              <a class="nav-link" @click="goto('home')" > Faqja kryesore </a>
                           </li>
                           <li>
                              <a class="nav-link" @click="goto('about')">Çfarë ofrojmë</a>
                           </li>
                           <li>
                              <a class="nav-link" @click="goto('pricing')">Pakot</a>
                           </li>
                           <li>
                              <a class="nav-link" @click="goto('services')">Shërbimet</a>
                           </li>
                           <li>
                              <a class="nav-link" @click="goto('faq')">Pyetjet</a>
                           </li>
                           <li>
                              <a class="nav-link" @click="goto('footer')">Kontakti</a>
                           </li>
                           <li>
                              <a class="nav-link" @click="gotoDataProtection()">Mbrojtja e të dhëvane</a>
                           </li>
                        </ul>
                     </nav>
                  </div>
               </div>
               <div class="col-xl-3 col-lg-2 col-6">
                  <div class="header-right-info d-flex align-items-center justify-content-end">
                     <!-- <div class="header-search" @click="handleSearch">
                           <button class="search-toggle" type="button"><i class="fa fa-search"></i></button>
                        </div> -->
                     <ul>

                        <li>
                           <div class="d-flex align-items-center justify-content-center">
                              <i class="fas fa-phone-volume" style="color:#ffcf00 ;"></i>
                           </div>
                           <div class="d-flex align-items-center justify-content-center">
                              <span><a href="tel:(+383)46-100-011" style="color:white ;">046 100 011</a></span>
                           </div>
                        </li>
                        <li>
                           <div class="d-flex align-items-center justify-content-center">
                              <i class="fal fa-envelope" style="color:#ffcf00;"></i>
                           </div>
                           <div class="mail-div d-flex align-items-center justify-content-center">
                              <span><a href="mailto:asistencarrugore.info@gmail.com"
                                    style="color:white ; ">asistencarrugore.info@gmail.com</a></span>
                           </div>
                        </li>
                     </ul>
                     <div class="sidebar__menu d-lg-none">
                        <div @click="menuOption.menuShow = !menuOption.menuShow" class="sidebar-toggle-btn ml-30"
                           id="sidebar-toggle">
                           <span class="line"></span>
                           <span class="line"></span>
                           <span class="line"></span>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </header>

   <!-- search popup area start -->
   <div :class="`${searchPopup ? 'search__popup transition-3 opened' : 'search__popup transition-3'}`">
      <div class="search__popup-close">
         <button type="button" class="search-popup-close-btn" @click="handleClose">
            <i class="fal fa-times"></i>
         </button>
      </div>
      <div class="search__popup-wrapper">
         <form action="#">
            <div class="search__popup-input">
               <input type="text" placeholder="Enter Your Keyword...">
               <button type="submit"><i class="far fa-search"></i> </button>
            </div>
         </form>
      </div>
   </div>
   <!-- search popup area end -->

   <!-- Mobile Menu Start -->
   <div class="fix">
      <div class="side-mobile-menu" :class="[menuOption.menuShow === true ? 'active' : '']">
         <div class="sidebar__wrapper">
            <div @click="menuOption.menuShow = !menuOption.menuShow" class="sidebar__close">
               <button class="sidebar__close-btn" id="sidebar__close-btn">
                  <i class="fal fa-times"></i>
               </button>
            </div>
            <div class="sidebar__content">
               <div class="logo mb-40">
                  <a href="index.html">
                     <img src="../../assets/img/logo/logo.png" alt="logo">
                  </a>
               </div>
               <div class="mobile-menu fix">
                  <div class="side-info-content sidebar-menu mm-menu">


                   <ul>
                           <li>
                              <a class="nav-link" @click="goto('home')" > Faqja kryesore </a>
                           </li>
                           <li>
                              <a class="nav-link" @click="goto('about')">Çfarë ofrojmë</a>
                           </li>
                           <li>
                              <a class="nav-link" @click="goto('pricing')">Pakot</a>
                           </li>
                           <li>
                              <a class="nav-link" @click="goto('services')">Shërbimet</a>
                           </li>
                           <li>
                              <a class="nav-link" @click="goto('faq')">Pyetjet</a>
                           </li>
                           <li>
                              <a class="nav-link" @click="goto('footer')">Kontakti</a>
                           </li>
                           <li>
                              <a class="nav-link" @click="gotoDataProtection()">Mbrojtja e të dhëvane</a>
                           </li>
                        </ul>
                  </div>
               </div>

               <div class="sidebar__contact mb-45">
                  <ul>
                     <li>
                        <div class="icon theme-color ">
                           <i class="fal fa-envelope"></i>
                        </div>
                        <div class="text theme-color ">
                           <span><a
                                 href="mailto:asistencarrugore.info@gmail.com">asistencarrugore.info@gmail.com</a></span>
                        </div>
                     </li>

                     <li>
                        <div class="icon theme-color">
                           <i class="fas fa-phone-volume"></i>
                        </div>
                        <div class="text theme-color">
                           <span><a href="tel:(+383)-46-100-011">046/100-011</a></span>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="body-overlay transition-3" :class="[menuOption.menuShow === true ? 'opened' : '']">
   </div>
   <!-- Mobile Menu End -->
</template>

<script>

export default {
    name:'HomeHeader',
    props:["scrollMethod"],
    data () {
       return {
          isSticky:false,
          searchPopup:false,
            menuOption: {
            menuShow: false,
            menuSearch: false,
            homeDropdown: false,
            serviceDropdown: false,
            pagesDropDown: false,
            blogDropdown: false,
            aboutDropdown: false,
            },
       }
    },
    methods : {
       handleSticky () {
          if(window.scrollY > 80){
             this.isSticky = true;
          }
          else{
             this.isSticky = false;
          }
       },
       handleSearch(){
          this.searchPopup = true;
       },
       handleClose(){
          this.searchPopup = false;
       },
       goto(id) {
         this.scrollMethod(id);
         if(this.menuOption.menuShow === true){
            this.menuOption.menuShow = false;
         }
       },
       gotoDataProtection(){
         this.$router.push('/data');
       }
       

    },
    mounted() {
            window.addEventListener('scroll',this.handleSticky)
   },
}
</script>

<style scoped>
@media screen and (min-width: 601px) {
  div.mail-div {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  div.mail-div {
    font-size: 12px;
  }
}
  a.nav-link:hover{
   cursor: pointer;
  }
</style>

