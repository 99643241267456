<template>
<div style="padding: 5%; align-items: center;">
<p style="text-align: center;"><strong>MBROJTJA DHE PERPUNIMI I TE DHENAVE PERSONALE</strong></p>
<p><span style="font-weight: 400;">ARRK L.L.C. ju ofron informacione n&euml; lidhje me menaxhimin e t&euml; dh&euml;nave tuaja personale kur ju vizitoni ose nd&euml;rveproni me faqen ton&euml; t&euml; internetit apo p&euml;rmes lidhjes s&euml; nje marredhenie kontraktuale me ne, q&euml;llimin e p&euml;rpunimit t&euml; tyre, parimet e mbrojtjes s&euml; t&euml; dh&euml;nave personale dhe t&euml; drejtat q&euml; ju takojn&euml; n&euml; p&euml;rputhje me Ligjin Nr. 06/L-082 p&euml;r Mbrojtjen e t&euml; Dh&euml;nave Personale. ARRK L.L.C. ju siguron q&euml; ka nd&euml;rmarr t&euml; gjitha masat adekuate t&euml; siguris&euml; teknike dhe organizative p&euml;r t&euml; mbrojtur secilin informacion q&euml; ju keni dh&euml;n&euml; p&euml;r t&euml; pranuar ndonj&euml; sh&euml;rbim t&euml; cilin ne e ofrojm&euml;.</span></p>
<p>&nbsp;</p>
<ul>
<li><strong><strong>P&euml;rkufizimet</strong></strong></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">P&euml;r q&euml;llim t&euml; k&euml;tij njoftimi p&euml;rkufizimet e p&euml;rdorura kan&euml; kuptimin sipas Ligjit Nr.06/L-082 p&euml;r Mbrojtjen e t&euml; Dh&euml;nave Personale.</span></p>
<p><strong>a).&nbsp; E dh&euml;n&euml; personale -</strong><span style="font-weight: 400;"> &ccedil;do informacion n&euml; lidhje me nj&euml; person fizik t&euml; identifikuar ose t&euml; identifikuesh&euml;m (&ldquo;subjekt i t&euml; dh&euml;nave&rdquo;); nj&euml; person fizik i identifikuesh&euml;m &euml;sht&euml; ai, i cili, mund t&euml; identifikohet drejtp&euml;rdrejt ose jo drejtp&euml;rdrejt, ve&ccedil;an&euml;risht duke iu referuar nj&euml; identifikuesi n&euml; baz&euml; t&euml; nj&euml; emri, nj&euml; numri identifikimi, t&euml; dh&euml;nave rreth vendndodhjes, nj&euml; identifikues online, ose nj&euml; apo m&euml; shum&euml; faktor&euml; specifik&euml; p&euml;r identitetin fizik, psikologjik, gjenetik, mendor, ekonomik, kulturor ose social t&euml; atij personi fizik;</span></p>
<p><strong>b). P&euml;rpunim -</strong><span style="font-weight: 400;"> &ccedil;do veprim ose seri veprimesh q&euml; kryhen ndaj t&euml; dh&euml;nave personale me mjete automatike ose jo, si: mbledhja, regjistrimi, organizimi, strukturimi, ruajtja, p&euml;rshtatja ose ndryshimi, t&euml;rheqja, konsultimi, p&euml;rdorimi, publikimi n&euml;p&euml;rmjet transmetimit, shp&euml;rndarja ose ofrimi, nj&euml;simi ose kombinimi, kufizimi, fshirja ose asgj&euml;simi;</span></p>
<p><strong>c). Kontrollues i t&euml; dh&euml;nave -</strong><span style="font-weight: 400;"> &ccedil;do person fizik ose juridik nga sektori publik ose privat q&euml; individualisht ose s&euml; bashku me t&euml; tjer&euml;t p&euml;rcakton q&euml;llimet dhe m&euml;nyrat e p&euml;rpunimit t&euml; t&euml; dh&euml;nave personale;</span></p>
<p><strong>d). P&euml;lqim i subjektit t&euml; t&euml; dh&euml;nave -</strong><span style="font-weight: 400;"> shprehje e lir&euml; e vullnetit t&euml; dh&euml;n&euml; lirisht, specifik, t&euml; informuar dhe t&euml; qart&euml; t&euml; d&euml;shirave t&euml; subjektit t&euml; t&euml; dh&euml;nave n&euml;p&euml;rmjet t&euml; cilit ai ose ajo, me nj&euml; deklarim ose me nj&euml; veprim t&euml; qart&euml; pohues, shpreh&euml; p&euml;lqimin e tij/saj p&euml;r p&euml;rpunimin e t&euml; dh&euml;nave personale n&euml; lidhje me t&euml;;</span></p>
<ol>
<li style="font-weight: 400;"><strong>P&euml;rgjegj&euml;s p&euml;r p&euml;rpunimin e t&euml; dh&euml;nave personale</strong><strong><br /></strong><span style="font-weight: 400;">P&euml;rgjegj&euml;s p&euml;r caktimin e q&euml;llimit p&euml;r p&euml;rpunimin e t&euml; dh&euml;nave personale &euml;sht&euml;:</span></li>
</ol>
<p><strong>ARRK L.L.C.</strong></p>
<p><span style="font-weight: 400;">rr.Deshmoret e Kombit pn</span></p>
<p><span style="font-weight: 400;">10000 Prishtin&euml;, Kosov&euml;</span></p>
<p><span style="font-weight: 400;">Tel:&nbsp; +383 (0)46 100 011</span></p>
<p><strong>E-mail:</strong><span style="font-weight: 400;">&nbsp;</span><a href="mailto:asistencarrugore.info@gmail.com"><span style="font-weight: 400;">asistencarrugore.info@gmail.com</span><span style="font-weight: 400;"><br /><br /></span></a></p>
<ol>
<li style="font-weight: 400;"><strong>T&euml; dh&euml;nat personale q&euml; ne p&euml;rpunojm&euml; p&euml;rmes faqes s&euml; internetit</strong><strong><br /></strong><span style="font-weight: 400;">Faqja e internetit e </span><span style="font-weight: 400;">ARRK L.L.C. </span><span style="font-weight: 400;">ka p&euml;r q&euml;llim q&euml; vizitor&euml;ve t&euml; faqes s&euml; saj t&rsquo;i ofroj&euml; informacione t&euml; p&euml;rgjithshme lidhur me veprimtarin&euml; e saj, dhe sh&euml;rbimet e ofruara nga </span><span style="font-weight: 400;">ARRK L.L.C.</span><span style="font-weight: 400;">, shpallje, njoftime t&euml; ndryshme dhe mund&euml;si p&euml;r pun&euml;sim n&euml; </span><span style="font-weight: 400;">ARRK L.L.C</span><span style="font-weight: 400;">.</span><span style="font-weight: 400;"><br /></span><strong>a).</strong><span style="font-weight: 400;">P&euml;rpunimi i t&euml; dh&euml;nave tuaja personale b&euml;het vetem n&euml; momentin kur ju aplikoni p&euml;r antar&euml;sim n&euml; </span><span style="font-weight: 400;">ARRK L.L.C. n&euml; njeren nga pakot aktive.</span><span style="font-weight: 400;"><br /></span><strong>b).</strong><span style="font-weight: 400;"> Me antar&euml;simin tuaj n&euml; ARRK L.</span><span style="font-weight: 400;">L.C. , ju keni dh&euml;n&euml; p&euml;lqimin tuaj p&euml;r p&euml;rpunimin e t&euml; dh&euml;nave tuaja personale.</span></li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li><strong><strong>Kategoria e t&euml; dh&euml;nave personale q&euml; p&euml;rpunohen nga ARRK L.L.C. dhe ruaja e tyre</strong></strong></li>
</ul>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>a).</strong><span style="font-weight: 400;">N&euml; momentin q&euml; ju d&euml;shironi t&euml; pranoni sh&euml;rbime t&euml; caktuara t&euml; cilat ofrohen nga ARRK L.L.C., k&euml;rkohet plot&euml;simi i disa t&euml; dh&euml;nave me an&euml; t&euml; t&euml; cilave ARRK L.L.C. ju identifikon.</span></p>
<p><span style="font-weight: 400;">ARRK L.L.C. mbledh dhe p&euml;rpunon k&euml;t&euml; kategori t&euml; t&euml; dh&euml;nave personale: emrin, mbiemrin, numrin e telefonit, adresen e vendbanimit, dhe t&euml; dhenat e vetures qe deshironi te antaresoni(Tipi,Modeli,Viti Prodhimit,Shasia,Targat).</span></p>
<p><strong>b).</strong><span style="font-weight: 400;">T&euml; dh&euml;nat personale t&euml; mbledhura do t&euml; ruhen vet&euml;m p&euml;r aq koh&euml; sa &euml;sht&euml; e nevojshme (gjate tere marredhenies kontraktuale dhe deri ne 3 vite pas perfundimit te saj) , duke respektuar afatet ligjore dhe rregullative dhe p&euml;r t&euml; p&euml;rmbushur q&euml;llimet e nevojshme biznesore.</span></p>
<p><strong>c).</strong><span style="font-weight: 400;">Ne e njohim gjithashtu r&euml;nd&euml;sin&euml; e mbrojtjes s&euml; privat&euml;sis&euml; aty ku p&euml;rfshihen f&euml;mij&euml;t. Sh&euml;rbimet tona t&euml; ofruar n&euml; k&euml;t&euml; faqe nuk jan&euml; t&euml; dizajnuara p&euml;r p&euml;rdorim dhe shfryt&euml;zim nga f&euml;mij&euml;t. Ne nuk mbledhim dhe p&euml;rpunojm&euml; t&euml; dh&euml;na personale me vet&euml;dije nga f&euml;mij&euml;t n&euml;n mosh&euml;n 18 vje&ccedil;are.</span></p>
<p><strong>d).</strong><span style="font-weight: 400;">ARRK L.L.C. nuk p&euml;rpunon dhe mbledh informacione t&euml; cilat konsiderohen &lsquo;t&euml; dh&euml;na personale t&euml; ndjeshme&rsquo; si t&euml; dh&euml;na personale q&euml; zbulojn&euml; origjin&euml;n etnike ose racore, pik&euml;pamjet politike ose filozofike, p&euml;rkat&euml;sin&euml; fetare, an&euml;tar&euml;simin n&euml; sindikat&euml; ose &ccedil;do e dh&euml;n&euml; p&euml;r gjendjen sh&euml;ndet&euml;sore ose jet&euml;n seksuale dhe t&euml; dh&euml;na biometrike.&nbsp;</span></p>
<p><strong><em>&nbsp;</em></strong></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">&nbsp;</span><strong>Siguria e t&euml; dh&euml;nave personale</strong></li>
</ol>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">P&euml;r t&euml; mbrojtur informacionin personal nga aksesi dhe p&euml;rdorimi i paautorizuar, ne p&euml;rdorim masa t&euml; siguris&euml; q&euml; p&euml;rputhen me ligjin n&euml; fuqi p&euml;r mbrojtjen e t&euml; dh&euml;nave personale.</span></p>
<p><span style="font-weight: 400;">Siguria dhe konfidencialiteti i t&euml; dh&euml;nave personale &euml;sht&euml; tejet i r&euml;nd&euml;sish&euml;m p&euml;r ne. Ne kemi nd&euml;rmarr&euml; masa strikte teknike, administrative dhe fizike t&euml; siguris&euml;.</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li><strong><strong>&Ccedil;far&euml; t&euml; drejta kam lidhur me p&euml;rpunimin e t&euml; dh&euml;nave t&euml; mia personale?&nbsp;</strong></strong></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Si shfryt&euml;zues i faqes son&euml; t&euml; internetit, n&euml; momentin q&euml; t&euml; dh&euml;nat tuaja p&euml;rpunohen ju keni disa t&euml; drejta t&euml; garantuara me legjislacionin n&euml; fuqi p&euml;r mbrojtjen e t&euml; dh&euml;nave personale, p&euml;r t&euml; cilat ne duhet t&euml; ju v&euml;m&euml; n&euml; dijeni.</span></p>
<p><strong>a).</strong><span style="font-weight: 400;"> E drejta juaj p&euml;r qasje n&euml; t&euml; dh&euml;nat personale</span></p>
<p><span style="font-weight: 400;">Ju keni t&euml; drejt&euml; t&euml; k&euml;rkoni nga ne t&euml; keni qasje n&euml; t&euml; dh&euml;nat e juaja personale n&euml;se po p&euml;rpunohen apo jo.</span></p>
<p><strong>b).</strong><span style="font-weight: 400;">E drejta juaj p&euml;r korrigjim t&euml; t&euml; dh&euml;nave personale</span></p>
<p><span style="font-weight: 400;">Ju keni t&euml; drejt&euml;n t&euml; k&euml;rkoni nga ne q&euml; t&euml; korigjojm&euml; informacionin personal q&euml; mendoni se &euml;sht&euml; i pasakt&euml;. Ju gjithashtu keni t&euml; drejt&euml; t&euml; na k&euml;rkoni t&euml; plot&euml;sojm&euml; informacionin q&euml; mendoni se &euml;sht&euml; i paplot&euml;.</span></p>
<p><strong>c).</strong><span style="font-weight: 400;">E drejta juaj p&euml;r kufizim t&euml; p&euml;rpunimit t&euml; t&euml; dh&euml;nave personale</span></p>
<p><span style="font-weight: 400;">Ju keni t&euml; drejt&euml; t&euml; na k&euml;rkoni t&euml; kufizojm&euml; p&euml;rpunimin e informacionit tuaj personal, sipas rrethanave t&euml; caktuar.</span></p>
<p><strong>d).</strong><span style="font-weight: 400;">E drejta juaj p&euml;r t&euml; kund&euml;rshtuar p&euml;rpunimin e t&euml; dh&euml;nave personale</span></p>
<p><span style="font-weight: 400;">Ju keni t&euml; drejt&euml; t&euml; kund&euml;rshtoni p&euml;rpunimi e t&euml; dh&euml;nave personale n&euml; lidhje me ju. ARRK L.L.C. nuk vazhdon me p&euml;rpunim t&euml; t&euml; dh&euml;nave personale p&euml;rve&ccedil; rasteve kur ARRK L.L.C.&nbsp; demonstron shkaqe detyruese legjitime p&euml;r p&euml;rpunimin q&euml; mbizot&euml;rojn&euml; mbi interesat, t&euml; drejtat dhe lirit&euml; e subjektit t&euml; t&euml; dh&euml;nave p&euml;r ngritjen, ushtrimin ose mbrojtjen e pretendimeve ligjore.</span></p>
<p><strong>e).</strong><span style="font-weight: 400;">E drejta juaj p&euml;r transferimin e t&euml; dh&euml;nave personale</span></p>
<p><span style="font-weight: 400;">Kjo e drejt&euml; vlen vet&euml;m p&euml;r t&euml; dh&euml;nat personale q&euml; ju na keni dh&euml;n&euml; dhe ne i p&euml;rpunojm&euml; ato vet&euml;m me p&euml;lqimin tuaj. Ju keni t&euml; drejt&euml;n q&euml; t&euml; k&euml;rkoni q&euml; ne t&euml; transferojm&euml; t&euml; dh&euml;nat personale q&euml; na keni dh&euml;n&euml; tek nj&euml; pal&euml; e tret&euml;.</span></p>
<p><strong>f).</strong><span style="font-weight: 400;">E drejta juaj p&euml;r t&euml; paraqitur ankes&euml; tek Agjencia p&euml;r Informim dhe Privat&euml;si</span></p>
<p><span style="font-weight: 400;">Ju n&euml; &ccedil;do koh&euml; keni t&euml; drejt&euml;n p&euml;r t&euml; parashtruar ankes&euml;n tuaj tek Agjencioni p&euml;r Informim dhe Privat&euml;si n&euml; qoft&euml; se mendoni se ARRK L.L.C.&nbsp; nuk &euml;sht&euml; duke respektuar t&euml; drejtat e juaja q&euml; i keni t&euml; garantuara me me Ligjin p&euml;r </span><strong>g).</strong><span style="font-weight: 400;">Mbrojtjen e t&euml; Dh&euml;nave Personale.</span></p>
<p><span style="font-weight: 400;">Nga&nbsp; ARRK L.L.C. nuk do t&euml; ju k&euml;rkohet asnj&euml; tarif&euml; shtes&euml; lidhur me k&euml;rkes&euml;n tuaj p&euml;r ushtrimin e t&euml; drejtave tuaja sa i p&euml;rket p&euml;rpunimit t&euml; t&euml; dh&euml;nave personale.&nbsp;</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li><strong><strong>Lidhja me faqet e tjera t&euml; internetit</strong></strong></li>
</ul>
<p>&nbsp;</p>
<p><strong>&nbsp;</strong></p>
<p><span style="font-weight: 400;">&nbsp;ARRK L.L.C. nd&euml;rvepron me klient&euml;t e saj edhe p&euml;rmes platformave t&euml; rrjeteve sociale si Facebook&reg;, Twitter&reg;, YouTube&reg; dhe LinkedIn&reg;. Ky Njoftim i Privat&euml;sis&euml; aplikohet vet&euml;m p&euml;r faqen ton&euml; t&euml; internetit www.asistencarrugore.com, andaj nuk kemi asnj&euml; ndikim n&euml; t&euml; dh&euml;nat q&euml; mbledhin dhe p&euml;rpunojn&euml; faqet e tjera t&euml; internetit.</span></p>
<p><span style="font-weight: 400;">&Ccedil;do p&euml;rmbajtje q&euml; postoni n&euml; faqet zyrtare t&euml; rrjeteve sociale t&euml; menaxhuara nga ARRK L.L.C. si fotografit&euml;, informacione, opinione ose ndonj&euml; infomacion personal q&euml; ju vini n&euml; dispozicion edhe tek pjes&euml;marr&euml;sit t&euml; tjer&euml; n&euml; k&euml;to rrjete sociale i n&euml;nshtrohen kushteve t&euml; p&euml;rdorimit dhe njoftimeve t&euml; privat&euml;sis&euml; s&euml; atyre platformave p&euml;rkat&euml;se.</span></p>
<p><span style="font-weight: 400;">Ju rekomandojm&euml; q&euml; p&euml;r faqet e tjera t&euml; interentit t&euml; lexoni njoftimet e tyre t&euml; privat&euml;sis&euml; lidhur me p&euml;rpunimin e t&euml; dh&euml;nave personale.</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol>
<li style="font-weight: 400;"><strong>Jam i detyruar t&euml; jap t&euml; dh&euml;nat e mia?</strong><strong><br /></strong><span style="font-weight: 400;">Si pjes&euml; e marr&euml;dh&euml;nies s&euml; biznesit, ju duhet t&euml; na jepni t&euml; gjith&euml; informacionin e nevojsh&euml;m p&euml;r t&euml; krijuar dhe vazhduar marr&euml;dh&euml;nien e kontraktuale me ju. </span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Megjithat&euml;, ju nuk jeni t&euml; detyruar t&euml; na jepni p&euml;lqimin p&euml;r p&euml;rpunimin e t&euml; dh&euml;nave, n&euml; qoft&euml; se k&euml;to t&euml; dh&euml;na nuk jan&euml; t&euml; nevojshme p&euml;r p&euml;rmbushjen e nj&euml; kontrate, ose n&euml; qoft&euml; se nuk k&euml;rkohet nga ligji apo rregulloret.</span></li>
</ol>
<p>&nbsp;</p>
<ol>
<li style="font-weight: 400;"><strong>Marketingu i drejtperdrejte</strong><strong><br /></strong><span style="font-weight: 400;">P&euml;r </span><span style="font-weight: 400;">t&euml; v&euml;n&euml; n&euml; jet&euml; marketingun e drejtp&euml;rderjt&euml;, mund te perdoren vetem te dhenat si me poshte: Emri dhe Mbiemri , numri i telefonit. Ju keni t&euml; drejt&euml; q&euml; n&euml; &ccedil;do koh&euml; t&euml; b&euml;ni nj&euml; kund&euml;rshtim p&euml;r k&euml;t&euml;, pa dh&euml;n&euml; asnj&euml; arsye n&euml; adres&euml;n e kontaktit m&euml; posht&euml;:&nbsp;</span><a href="mailto:asistencarrugore.info@gmail.com"><strong>asistencarrugore.info@gmail.com</strong></a><strong>, </strong><span style="font-weight: 400;">dhe</span> <span style="font-weight: 400;">kontrolluesi ka detyrimin t&euml; fshij&euml; t&euml; dh&euml;nat tuaja, pa vonesa t&euml; panevojshme,</span></li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li><strong><strong>K&euml; mund t&euml; kontaktoj lidhur me informacione rreth p&euml;rpunimit t&euml; t&euml; dh&euml;nave personale nga ARRK L.L.C. ?</strong></strong></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">N&euml;se mendoni se informacioni i p&euml;rfshir&euml; n&euml; k&euml;t&euml; Njoftim t&euml; Privat&euml;sis&euml; lidhur me p&euml;rpunimin e t&euml; dh&euml;nave personale p&euml;rmes faqes s&euml; internetit nuk &euml;sht&euml; i mjaftuesh&euml;m apo d&euml;shironi q&euml; t&euml; paraqitni k&euml;rkes&euml; p&euml;r t&euml; ushtruar t&euml; drejtat e juaja sa i p&euml;rket mbrojtjes s&euml; t&euml; dh&euml;nave personale ju lutem na d&euml;rgoni k&euml;rkes&euml;n tuaj n&euml; form&euml; t&euml; shkruar duke na kontaktuar p&euml;rmes adres&euml;s son&euml; elektornike asistencarrugore.info@gmail.com apo p&euml;rmes sms ne numri 0038346100011.</span></p>
<p><span style="font-weight: 400;">N&euml; qoft&euml; se ne nuk mund t&euml; ju identifikojm&euml; n&euml; momentin kur parashtroni k&euml;rkes&euml;n tuaj mund t&euml; k&euml;rkojm&euml; nga ju q&euml; t&euml; na ofroni nj&euml; kopje t&euml; dokumentit te vetures tuaj, me an&euml; t&euml; t&euml; cilit ne vet&euml;m do t&euml; verifikojm&euml; identitetin tuaj.</span></p>
<p>&nbsp;</p>
<ol>
<ol>
<li style="font-weight: 400;"><strong>E Drejta e fshirjes (&lsquo;E drejta p&euml;r t&rsquo;u harruar&rsquo;)</strong><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;">Ju keni t&euml; drejt&euml;n q&euml; t&euml; k&euml;rkoni nga kontrolluesi t&euml; b&euml;j&euml; fshirjen e t&euml; dh&euml;nave tuaja personale n&euml; adres&euml;n e kontaktit m&euml; posht&euml;:&nbsp;</span><a href="mailto:asistencarrugore.info@gmail.com"><strong>asistencarrugore.info@gmail.com</strong></a><span style="font-weight: 400;">,sapo t&euml; dh&euml;nat personale nuk jan&euml; m&euml; t&euml; nevojshme n&euml; lidhje me q&euml;llimet, p&euml;r t&euml; cilat ato jan&euml; mbledhur.</span><span style="font-weight: 400;"><br /></span><strong>a).</strong> <span style="font-weight: 400;">Duhet te jeni te vetedijshem n&euml;se ju nuk pranoni t&euml; na vini n&euml; dispozicion t&euml; dh&euml;nat e nevojshme, ne p&euml;rgjith&euml;sisht refuzojm&euml; t&euml; lidhim nj&euml; kontrat&euml;, apo nese depozitoni nje kundershtim , ne nuk jemi n&euml; gjendje t&euml; vazhdojm&euml; ekzekutimin e nj&euml; kontrate ekzistuese, dhe jemi t&euml; detyruar t&euml; nd&euml;rpresim k&euml;t&euml; kontrat&euml;.</span></li>
</ol>
</ol>
<p>&nbsp;</p>
<ul>
<li><strong><strong>P&euml;rdit&euml;simet e k&euml;tij njoftimi</strong></strong></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Ky njoftim i privat&euml;sis&euml; mund t&euml; p&euml;rdit&euml;sohet koh&euml; pas kohe p&euml;r t&euml; qen&euml; n&euml; pajtueshm&euml;ri me k&euml;rkesat e legjislacionit p&euml;r mbrojtjen e t&euml; dh&euml;nave personale.</span></p>
</div>
</template>