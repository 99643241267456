<template>
        <!-- <Header/> -->
    <Breadcrumb title="MBROJTJA DHE PERPUNIMI I TE DHENAVE PERSONALE" subtitle="" />
    <DataPrivacy/>
    <Footer/>\
</template>
<script>
import Breadcrumb from '../../components/common/Breadcrumb.vue';
import DataPrivacy from '../../components/DataPrivacy/DataPrivacy.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
    components:{
        Breadcrumb,
        DataPrivacy,
        Footer,
    },
}
</script>
