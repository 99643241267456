import { createWebHistory,createRouter } from "vue-router";
import Home from '../pages/Home/Home.vue';
// import HomeTwo from '../pages/HomeTwo/HomeTwo.vue';
// import About from '../pages/About/About.vue';
 import DataProtection from '../pages/DataProtection/DataProtection.vue';
// import Services from '../pages/Services/Services.vue';
// import ServicesDetails from '../pages/ServicesDetails/ServicesDetails.vue';
// import Pricing from '../pages/Pricing/Pricing.vue';
// import Portfolio from '../pages/Portfolio/Portfolio.vue';
// import PortfolioDetails from '../pages/PortfolioDetails/PortfolioDetails.vue';
// import Team from '../pages/Team/Team.vue';
// import TeamDetails from '../pages/TeamDetails/TeamDetails.vue';
// import Faq from '../pages/Faq/Faq.vue';
//import ErrorPage from '../pages/404/404.vue';
// import Blog from '../pages/Blog/Blog.vue';
// import BlogDetails from '../pages/BlogDetails/BlogDetails.vue';
import Contact from '../pages/Contact/Contact.vue';
import Thanks from '../pages/Thanks/Thanks.vue';

const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            path:'/',
            component:Home,
        },
        {
            path:'/home',
            component:Home,
        },
        {
            path:'/thanks',
            component:Thanks,
        },
        {
            path:'/data',
            component:DataProtection,
        },
        {
            path:'/antaresohu/:id',
            name:'antaresohu',
            component:Contact,
            props:true
        },
        {
            path:'/:notFound(.*)', 
            redirect: '/',
        }
    ]
})

router.beforeEach((to, from, next) => {
    next();
    window.scrollTo(0, 0)
});

export default router