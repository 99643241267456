<template>
    <div class="col-xl-12 col-lg-12">
            <div class="faq-content faq-white">
               <h2>Pyetjet më të shpeshta!</h2>
                <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                           <h2 class="accordion-header" id="headingTwo">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                 data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                 Çfarë është sigurimi i asistences në rrugë?
                              </button>
                           </h2>
                           <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                              data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                 <p>Mbulimi i asistencës në rrugë është krijuar për të ndihmuar një automjet të dëmtuar ose të paaftë për të udhëtuar. 
                                    Kur hasni probleme me automjetin, mund të na kontaktoni duke telefonuar linjën emergjente.
                                    Shërbimi i asistences më pas do të dërgojë dikë për t'ju ndihmuar me nevojat tuaja për problemet e vetures, bartjen deri në autoservis apo bartjen deri në adresen tuaj.</p>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <h2 class="accordion-header" id="headingThree">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                 data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                 Çfarë mbulon Asistenca Rrugore?
                              </button>
                           </h2>
                           <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                              data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                 <p>
                                    <ul>
                                       <li>Kur makina juaj prishet dhe ka nevojë për riparime</li>
                                       <li>Kur ju duhet një auto bartje deri në një autoservis apo adresen tuaj</li>
                                       <li>Kur makina juaj ka problem me gomë të shpuar që kërkon riparim apo zëvendësim</li>
                                       <li>Kur bateria e vetures tuaj nuk funksionon dhe keni nevojë  një nisje të shpejtë ( ndezje me kabllo).</li>
                                    </ul>
                                 </p>
                              </div>
                           </div>
                        </div>
                                              <div class="accordion-item">
                           <h2 class="accordion-header" id="headingOne">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                 data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                 A është për mua sigurimi asistencë rrugore?
                              </button>
                           </h2>
                           <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                 <p>Sigurimi i asistencës rrugore është një nevojë për të gjithë!
                                    Një makinë që është më e vjetër se 10 vite ka shumë gjasa të përfundojë me prishje gjatë rrugës. 
                                    Pra, një sigurim asistence për ndihmë në rrugë mund të jetë një vendim i mençur.
                                    Por edhe makinat e reja nuk është se janë të imunizuara ndaj problemeve si p.sh. probleme me gomat, bateritë etj. 
                                    Sigurimi i mbulimit të asistences në rrugë do t'ju ndihmojë të ktheheni në udhëtimin tuaj pavarësisht problemit që keni. 
                                    </p>
                              </div>
                           </div>
                        </div>
                        <div class="accordion-item">
                           <h2 class="accordion-header" id="headingThree1">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                 data-bs-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                                 Asistenca Rrugore a bëhet për personin apo veturen?
                              </button>
                           </h2>
                           <div id="collapseThree1" class="accordion-collapse collapse" aria-labelledby="headingThree1"
                              data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                 <p>Pakot e mbulimit të asistences rrugore të ofruara nga ne vlejnë vetëm për veturën.<br/>
                                    Nuk ka rënësi kush e drejton veturën, mjafton të jetë e pranishme kartela e anëtarësimit.</p>
                              </div>
                           </div>
                        </div>
              </div>
          </div>
      </div> 
</template>

<script>
export default {
    name:'FaqLeft',
}
</script>