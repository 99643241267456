<template>
      <div class="main-services black-bg pt-120 pb-90" 
      :style="{backgroundImage:'url('+ require('../../assets/img/pattern/dot.png')+')',}" 
      data-background="assets/img/pattern/pt1.png">
            <div class="container">
               <div class="row mb-60">
                  <div class="col-12">
                     <div class="sec-wrapper text-center">
                        <h2 class="section-title text-white">SHËRBIMET TONA</h2>
                     </div>
                  </div>
               </div>

               <div class="row text-center">
                  <div v-for="service in serviceData" :key="service.id" class="col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-30">
                     <div class="mservices fix" style="height:100%;">
                        <div class="ms-line-shape">
                           <div class="ms-line"></div>
                           <div class="ms-line2"></div>
                        </div>
                        <div class="mservices__icon">
                           <i :class="`${service.icon}`"></i>
                        </div>
                        <div class="mservices__text">
                           <h2 class="ms-title">
                              <p>{{service.text}}</p>
                           </h2>
                        </div>
                     </div>
                  </div>
              
               </div>
            </div>
         </div>
</template>

<script>
export default {
    name:'HomeServices',
    data () {
       return {
          serviceData:[
             {
                id:"1",
                icon:'fas fa-car-battery',
                title:'DEFEKTET E VOGLA',
                text:'Asistencë duke filluar që nga defektet e vogla si: ndezja e veturës ne rast të mungesës së rrymës (asistimi me kabllo).'
                
             },
             {
                id:"2",
                icon:'fas fa-car-crash',
                title:'RAST AKSIDENTI',
                text:'Asistencë në rastet e aksidentimit të automjetit.'
                
             },
             {
                id:"3",
                icon:'fas fa-car-mechanic',
                title:'TRANSPORTIMI I AUTOMJETIT',
                text:'Në rast të pamundësisë së menjanimit të defekteve të lartëshenuara ose defekteve të tjera automjeti do të transportohet deri ne vendin (lokacionin) e parë që kërkon anetari'
             },
             {
                id:"4",
                icon:'fas fa-infinity',
                title:'NUMËR I PAKUFIZUAR I ASISTIMEVE',
                text:`ARRK merr përsiper të ofroj asistime në numër të pa kufizuar për këto shërbime ( pa limit ) , dhe anëtari ka të drejtë që t'i kërkoj vetëm për automjetin që është përfshirë në këtë kontratë`
             }

          ]
       }
    }
}
</script>
