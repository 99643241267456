<template>
    <!-- <Header/> -->
    <Breadcrumb title="Antarësohu" subtitle="Antarësohu" />
    <ContactArea :title="selectedPackage.title" :price="selectedPackage.price" :packageID="selectedPackage.id"/>
    <Footer/>\
</template>

<script>
import Breadcrumb from '../../components/common/Breadcrumb.vue';
import ContactArea from '../../components/Contact/ContactArea.vue';

import Footer from '../../components/Home/Footer.vue';
import pricingJson from '@/json/pricing_data.json'

export default {
    name:'ContactPage',
    props:{
        id:{
          Type:Number,
          default: 0

        },
        title:{
            Type:String,
            default: null
        },
        price:{
            Type:String,
            default:null
        }
    },
    data(){
        return{
            selectedPackage:{},
            pricing:[]
        }
    },
    components:{
        Breadcrumb,
        ContactArea,
        Footer,
    },
    mounted(){
        this.pricing = pricingJson;
        this.selectedPackage = this.pricing.find(p => p.id === Number.parseInt(this.id))
        if(!this.selectedPackage){
            this.$router.push('/')
        }
    }
}
</script>