<template>
    <section class="pricing__area pt-100 pb-110">
            <div class="container">
               <div class="row mb-60">
                  <div class="col-12">
                     <div class="sec-wrapper text-center">
                        <h5>Çmimorja</h5>
                        <h2 class="section-title">Pakot aktive</h2>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div v-for="pricing in pricingData" :key="pricing.id" class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                     <div :class="pricing.activeClass ? `pricing__item text-center transition-3 mb-30 ${pricing.activeClass}` : 'pricing__item text-center transition-3 mb-30'">
                        <div class="pricing__header mb-25">
                           <h3>{{pricing.title}}</h3>
                           <p><span v-html="pricing.subject"></span></p>
                        </div>
                        <div class="pricing__tag d-flex align-items-start justify-content-center mb-30">
                           <span></span>
                           <h4>{{pricing.price}} € </h4>
                        </div>
                        <h3>Për një vit</h3>
                        <div class="pricing__header mb-25">
                           <h3>(Pa limit asistime)</h3>
                        </div>
                        <div class="pricing__switch mb-10">
                           <button type="button">Përfito nga çmimi promocional</button>
                        </div>
                        <div class="pricing__buy mb-20">
                           <router-link :to="{name: 'antaresohu', params:{id:pricing.id}}" class="tp-btn w-100" >Antarsohu</router-link>
                        </div>
                        <div class="pricing__features text-start">
                           <ul>
                              <li>24/7 Asistencë</li>
                              <li>Asistencë në ndërrim të gomës</li>
                              <li>Asistencë në ndezje të veturës me kabllo</li>
                              <li>Bartje të automjetit sipas kërkesës</li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
</template>

<script>
export default {
    name:'PricingArea',
    data () {
        return {
            pricingData:[
                         {
                    id:4,
                    title:'PAKO RAJON',
                    price:'49.99',
                   // activeClass:'active',
                    subject:'Mbulon tërë Rajonin (Kosovë, Shqipëri, Mali i Zi, Maqedoni Veriore dhe Serbi) për vetëm'
                },
                {
                    id:3,
                    title:'PAKO KOSOVE SHQIPËRI',
                    price:'39.99',
                    subject:'Mbulon tërë teritorin e Kosovës dhe të Shqipërisë për vetëm'
                },
               {
                    id:2,
                    title:'PAKO KOSOVA',
                    price:'29.99',
                    subject:'Mbulon tërë teritorin e Kosovës <br> për vetëm'
                },
            ]
        }
    }
}
</script>